import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InteractionService {
  dataSub: BehaviorSubject<any>;
  dataUser: BehaviorSubject<any>;
  data: string ="";
  private dataSub2 = new BehaviorSubject<string>(this.data);
  private dataUser2 = new BehaviorSubject<string>(this.data);
  currentData = this.dataSub2.asObservable();
  currentUser = this.dataUser2.asObservable();

  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this.dataSub = new BehaviorSubject({});
    this.dataUser = new BehaviorSubject({});
  }

  changeDataSub(newData: string){
    this.dataSub.next(newData);
  }

  changeDataSub2(newData: string){
    this.dataSub2.next(newData);
  }

  changeDataUser(newData: string){
    this.dataUser.next(newData);
  }

  changeDataUser2(newData: string){
    this.dataUser2.next(newData);
  }
}
