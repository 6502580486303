
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as snippet from 'app/main/apps/request/request-view/swiper.snippetcode';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgTemplateOutlet } from '@angular/common';
import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/auth/service';
import { CoreConfigService } from '@core/services/config.service';
import { RequestViewService } from 'app/main/apps/request/request-view/request-view.service';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements  OnInit, OnDestroy {
  public contentHeader: object;
  public centeredSlideIndex = 2;
  public centeredSlide2Index = 2;
  public rows: any;
  public agents: any;
  public supportse1: any;
  public admins: any;
  public path = `${environment.API_URL}/files/`;
  public pathboxes = `${environment.API_URL}/boxes/`;
  public pathreals = `${environment.API_URL}/reals/`;

  @ViewChild('wizard')
  public wizardRef: TemplateRef<any>; 
  public img_clicked : string;

  // snippet code variables
  public _snippetCodeSwiperDefault = snippet.snippetCodeSwiperDefault;
  public _snippetCodeSwiperNavigations = snippet.snippetCodeSwiperNavigations;
  public _snippetCodeSwiperPaginations = snippet.snippetCodeSwiperPaginations;
  public _snippetCodeSwiperProgress = snippet.snippetCodeSwiperProgress;
  public _snippetCodeSwiperMultiple = snippet.snippetCodeSwiperMultiple;
  public _snippetCodeSwiperCenteredSlides1 = snippet.snippetCodeSwiperCenteredSlides1;
  public _snippetCodeSwiperCenteredSlides2 = snippet.snippetCodeSwiperCenteredSlides2;
  public _snippetCodeSwiperFadeEffect = snippet.snippetCodeSwiperFadeEffect;
  public _snippetCodeSwiperCubeEffect = snippet.snippetCodeSwiperCubeEffect;
  public _snippetCodeSwiperCoverflow = snippet.snippetCodeSwiperCoverflow;
  public _snippetCodeSwiperAutoplay = snippet.snippetCodeSwiperAutoplay;
  public _snippetCodeSwiperLazy = snippet.snippetCodeSwiperLazy;
  public _snippetCodeSwiperResponsive = snippet.snippetCodeSwiperResponsive;
  public url = this.router.url;
  public lastValue;
  public urlLastValue;
  public idRequest;
  public data;
  public avatarImage: string;
  public disabled = true;
  public status = false;
  public trackingexist = false;
  public existAgent = false;
  public existE1 = false;
  public track_nbr = "";
  public track_url = "";
  public selectIcon = [
    {
      id: 1,
      name: 'Pending',
      icon: 'fa fa-chrome'
    },
    {
      id: 1,
      name: 'On Communication',
      icon: 'fa fa-chrome'
    },
    {
      id: 1,
      name: 'Awaiting Paiement',
      icon: 'fa fa-chrome'
    },
    {
      id: 2,
      name: 'Processing',
      icon: 'fa fa-firefox'
    },
    {
      id: 3,
      name: 'Shipped',
      icon: 'fa fa-safari'
    },
    {
      id: 4,
      name: 'Completed',
      icon: 'fa fa-opera'
    }
  ];
  public selectIconSelected = this.selectIcon[0].name;
  public agentSelected: any;
  public e1Selected: any;
  public affectedagent: any;
  @ViewChild('modalForm') modalForm: NgTemplateOutlet;
  public requestFiles: any;
  public requestFilesBoxes: any;
  public requestFilesReals: any;

  private _unsubscribeAll: Subject<any>;

  constructor(private _coreConfigService: CoreConfigService, private router: Router, private modalService: NgbModal, private _authenticationService: AuthenticationService, private _httpClient: HttpClient) {
    this._unsubscribeAll = new Subject();
    this.urlLastValue = this.url.substr(this.url.lastIndexOf('/') + 1);
    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
   }

  ngOnInit(): void {
    //this.data = this._authenticationService.getPost(this.urlLastValue); 
    this._authenticationService.getPost(this.urlLastValue)
      .pipe(first())
      .subscribe(
        data => {
          this.idRequest = data.id
          this.data = data;
          this.selectIconSelected = data.status;
          this.getFilesByPost();
          this.getFilesBoxesByPost();
          this.getFilesRealsByPost();
          if(this.data.status !== "Pending" && this.data.status !== "On Communication" && this.data.status !== "Awaiting Paiement" && this.data.status !== "Processing"){
            this.status = true;
          } else this.status = false
          if(this.data.track_url !== "" && this.data.track_url !== null){
            this.trackingexist = true;
            this.track_nbr = this.data.track_nbr;
            this.track_url = this.data.track_url;
          }
          if(this.data.agent !== null){
            this.agentSelected = this.data.agent.id;
            this.existAgent = true;
          }
          if(this.data.e1 !== null){
            this.e1Selected = this.data.e1.id;
            this.existE1 = true;
          }
        }
      );
  }

  getFilesByPost(){
    this._authenticationService.getFilesByPost(this.idRequest).pipe(first())
    .subscribe(
      data => {
        this.requestFiles = data;
        if(data != 422){
        } else {
        }
        
      },
      error => {
      }
    );
  }

  getFilesBoxesByPost(){
    this._authenticationService.getFilesBoxesByPost(this.idRequest).pipe(first())
    .subscribe(
      data => {
        this.requestFilesBoxes = data;
        if(data != 422){
        } else {
        }
        
      },
      error => {
      }
    );
  }

  getFilesRealsByPost(){
    this._authenticationService.getFilesRealsByPost(this.idRequest).pipe(first())
    .subscribe(
      data => {
        this.requestFilesReals = data;
      },
      error => {
      }
    );
  }

  goToLink(url: string){
    console.log("url "+url);
    window.open("https:"+url, "_blank");
  }

  modalOpenForm(modalForm) {
    this.modalService.open(modalForm);
  }

  showUpdDiv(){
    //document.getElementById("track_add").style.display = "none";
    document.getElementById("track_details").style.display = "none";
    document.getElementById("track_upd").style.display = "block";
  }

  openModal(name) {
    console.log("clicked"+name);
    this.modalService.open(this.wizardRef);
    this.img_clicked = environment.API_URL+"/files/"+name;
    document.getElementById('myModal').style.display = "block";
   }
   closeModal() {
    document.getElementById('myModal').style.display = "none";
   }

  /**
   * On destroy
   */
   ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // swiper
  public swiperNavigations: SwiperConfigInterface = {
    navigation: true
  };

  public swiperPaginations: SwiperConfigInterface = {
    pagination: true
  };

  public swiperProgress: SwiperConfigInterface = {
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar'
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  };

  public swiperMultiple: SwiperConfigInterface = {
    slidesPerView: 3,
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  };

}
