import { environment } from './../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ProfileService implements Resolve<any> {
  rows: any;
  onPricingChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    // Set the defaults
    this.onPricingChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    var id = Number(localStorage.getItem('iduser'));
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getDataTableRows(id)]).then(() => {
        resolve();
      }, reject);
    });
  }

  /**
   * Get rows
   */
  getDataTableRows(id: number): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(`${environment.API_URL}/api/users/${id}`).subscribe((response: any) => {
        this.rows = response;
        this.onPricingChanged.next(this.rows);
        resolve(this.rows);
      }, reject);
    });
  }

  changePassword(password: string, newpassword: string) {
    var id = localStorage.getItem('iduser');
    return this._httpClient
      .put<any>(`${environment.API_URL}/api/updpsw/${id}`, { "password": password,"newpassword": newpassword})
      .pipe(
        map(response => {
          return response.status;
        })
      );
  }

  changeImage(myfile: File) {
    //console.log("name "+myfile.name);
    var id = localStorage.getItem('iduser');
    const form = new FormData;
    form.append('myfile', myfile);
    //console.log("ok");
    
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers = headers.append('Authorization', `Bearer ${localStorage.getItem('token')}`);

    
    
    
    
    //fd.append('myfile', myfile, myfile.name);
    return this._httpClient
      //.post<any>(`${environment.API_URL}/api/upload/${id}`, fd)
      .post<any>(`${environment.API_URL}/api/upload/${id}`, form, {headers})
      .pipe(
        map(response => {
          return response;
        })
      );
  }
}
