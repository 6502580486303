import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { first } from 'rxjs/operators';
import { SocketService } from 'app/main/apps/chat/services/socket.service';
import { Event } from 'app/main/apps/chat/models/event';
import { Message } from 'app/main/apps/chat/models/message';

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: notification;
  public rows: any;
  public userconnected : any;
  ioConnection: any;

  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(private _notificationsService: NotificationsService, private _router: Router, private socketService: SocketService) {}

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.initIoConnection();
    this.userconnected = JSON.parse(localStorage.getItem('currentUser'));
    var id = this.userconnected.id;
    this.getNotifications(id);
    this._notificationsService.onApiDataChange.subscribe(res => {
      this.notifications = res;
    });
  }
/*
  getDate(date: Date) {
    var date = new Date(date);
    return date.getFullYear()+"-"+date.getMonth()+"-"+date.getDate();
  }

  getTime(date: Date) {
    var date = new Date(date);
    return date.getHours()+"-"+date.getMinutes();
  }*/

  readAllNotif(){
    this._notificationsService.readAllNotif(this.userconnected.id).subscribe((result: any) => {
      if (result === 200) {
        this.rows = [];
        /*
        this.rows.forEach((value,index)=>{
            if(value.id==id) this.rows.splice(index,1);
        });
        */
      }
      return result;
      
   });
  }

  getNotifications(id: number) {
    this._notificationsService.getNotifications(id).pipe(first())
    .subscribe(
      data => {
        this.rows = data;
      },
      error => {
        console.log("errors");
      }
    );
  }

  redirect(type: string, message: any) {
    this.setNotifVisible(message.id);
    if (type==="POST" || type==="STATUS"){
      this._router.navigate(['/apps/request/request-view/'+message.post.id]);
    } else if (type==="POSTE1" || type==="POSTCONFIRMED" ) {
      this._router.navigate(['/apps/request/request-view/'+message.post.id]);
    } else if (type==="CLIENT") {
      this._router.navigate(['/apps/user/user-view/'+message.client.id]);
    } else if (type==="INVOICE") {
      this._router.navigate(['/apps/invoices/invoices-view/'+message.invoice.id]);
    } else if (type==="RECLAMATION" || type==="RESPONSE") {
      this._router.navigate(['/apps/reclamation/reclamation-view/'+message.reclamation.id]);
    } else {
      this._router.navigate(['/apps/request/request-view/'+message.message.post.id]);
    }
  }

  setNotifVisible(id) {
    this._notificationsService.setNotifVisible(id).subscribe((result: any) => {
      if (result === 200) {
        this.rows.forEach((value,index)=>{
          if(value.id==id) this.rows.splice(index,1);
      });
      }
      return result;
      
   });
    
  }

  private initIoConnection(): void {
    this.socketService.initSocket();
    this.socketService.joinRoom(0);
    this.ioConnection = this.socketService.onMessage()
      .subscribe((message: Message) => {
        /*
        if (message.type === "MESSAGE" && message.to === this.userconnected.id) {
          var id = this.userconnected.id;
          this.getNotifications(id);
        }*/
        var id = this.userconnected.id;
        this.getNotifications(id);
        // this.messages.push(message);
      });


    this.socketService.onEvent(Event.CONNECT)
      .subscribe(() => {
        console.log('connected');
      });

    this.socketService.onEvent(Event.DISCONNECT)
      .subscribe(() => {
        console.log('disconnected');
      });
  }
  
}
