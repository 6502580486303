import { InteractionService } from './../../../main/pages/profile/interaction.service';
import { Component, OnDestroy, OnInit, HostBinding, HostListener, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from 'app/auth/service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreMediaService } from '@core/services/media.service';

import { User } from 'app/auth/models';

import { coreConfig } from 'app/app-config';
import { Router } from '@angular/router';
import { SocketService } from 'app/main/apps/chat/services/socket.service';
import { Event } from 'app/main/apps/chat/models/event';
import { Message } from 'app/main/apps/chat/models/message';
import { environment } from 'environments/environment';
import { UserEditService } from 'app/main/apps/user/user-edit/user-edit.service';
import { StatisticsadService } from 'app/main/apps/statistics-admin/statisticsad.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;

  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  public currentUser: User;
  public avatarImage: string;
  public usernamedata: string;

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;
  public userconnected : any;

  ioConnection: any;

  @HostBinding('class.fixed-top')
  public isFixed = false;

  @HostBinding('class.navbar-static-style-on-scroll')
  public windowScrolled = false;
  //private dataSubject: string="init subject";

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == 'navbar-static-top' &&
      this.coreConfig.layout.type == 'horizontal'
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    private interactionService: InteractionService,
    public _translateService: TranslateService, 
    private socketService: SocketService,
    private _userEditService: UserEditService,
    private statisticsadService: StatisticsadService,
    private router: Router
  ) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
    //this.usernamedata = localStorage.getItem('username');
    this.languageOptions = {
      en: {
        title: 'English',
        flag: 'us'
      },
      fr: {
        title: 'French',
        flag: 'fr'
      },
      de: {
        title: 'German',
        flag: 'de'
      },
      ar: {
        title: 'Arabic',
        flag: 'ma'
      },
      pt: {
        title: 'Portuguese',
        flag: 'pt'
      }
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }
/*
  getData(): string {
    return this.interactionService.sharedData;
  }

  setData(value: string) {
    this.interactionService.sharedData = value;
  }*/

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;
    console.log("cliqued");
    setTimeout(() => this._coreConfigService.onLanguageChanged.next(language), 2);
    //this._coreConfigService.onLanguageChanged.next(language);

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig({ app: { appLanguage: language } }, { emitEvent: true });

    var id = this.userconnected.id;
    if(language.toUpperCase() === "FR" || language.toUpperCase() === "EN"){
      this._userEditService
      .updateLang(id, language.toUpperCase())
      .pipe(first())
      .subscribe(
        data => {
          console.log("succesfully updated");
        },
        error => {
          console.log("errors");
        }
    );
    }
    if(window.location.href.endsWith("/statistics-admin")){
      //window.location.reload();
    }
  }

  reloadComponent(self:boolean,urlToNavigateTo ?:string){
    //skipLocationChange:true means dont update the url to / when navigating
   console.log("Current route I am on:",this.router.url);
   const url=self ? this.router.url :urlToNavigateTo;
   this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
     this.router.navigate([`/${url}`]).then(()=>{
       console.log(`After navigation I am on:${this.router.url}`)
     })
   })
 }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.currentSkin = config.layout.skin;
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem('prevSkin');

    if (this.currentSkin === 'dark') {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : 'default' } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem('prevSkin', this.currentSkin);
      this._coreConfigService.setConfig({ layout: { skin: 'dark' } }, { emitEvent: true });
    }
  }

  /**
   * Logout method
   */
  logout() {
    this._authenticationService.logout();
    //this._router.navigate(['/pages/authentication/login-v2']);
    this._router.navigate(['/login']);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    /*
    this.interactionService.currentData.subscribe((res) => {
      console.log("rees"+res);
      this.dataSubject = res;
      console.log("rees"+this.dataSubject);
    });

    this.interactionService.dataSub.subscribe((res) => {
      console.log("rees"+res);
    });*/
    this.userconnected = JSON.parse(localStorage.getItem('currentUser'));
    //this.usernamedata = localStorage.getItem('username');
    this.usernamedata = localStorage.getItem('username');
    this.interactionService.dataSub.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
      //this.data = response;
      if(response !== ""){
        this.avatarImage = response;
      } else this.avatarImage = "assets/images/portrait/small/avatar-s-2.jpg";
      //console.log("ok from navbar");
    });
    this.interactionService.dataUser.pipe(takeUntil(this._unsubscribeAll)).subscribe(response => {
      //this.data = response;
      if(response !== this.usernamedata && response && (Object.keys(response).length !== 0)  ){
        //console.log("response "+JSON.stringify(response));
        this.usernamedata = response;
      } 
      //console.log("ok from navbar");
    });
    // get the currentUser details from localStorage
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(this.currentUser.avatar !== "avatar-s-2.jpg"){
      this.avatarImage = `${environment.API_URL}/uploads/`+localStorage.getItem('avatar');
    } else this.avatarImage = "assets/images/portrait/small/avatar-s-2.jpg";
    // Subscribe to the config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
      this.horizontalMenu = config.layout.type === 'horizontal';
      this.hiddenMenu = config.layout.menu.hidden === true;
      this.currentSkin = config.layout.skin;

      // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
      if (this.coreConfig.layout.type === 'vertical') {
        setTimeout(() => {
          if (this.coreConfig.layout.navbar.type === 'fixed-top') {
            this.isFixed = true;
          }
        }, 0);
      }
    });

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == 'horizontal') {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
        const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
        if (isFixedTop) {
          this.isFixed = false;
        } else {
          this.isFixed = true;
        }
      });
    }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang
    });


    
    /*
    this.interactionService.currentData.subscribe(dataSub => {
      if (dataSub != null) {
      } else console.log("malheureusement");
    }); */
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }


  
}
