import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/auth/service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _authenticationService: AuthenticationService) {}

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      /*request = request.clone({
        setHeaders: {
          Accept: 'application/json',
          'Content-Type' : 'image/jpeg',
          'Content-Disposition' : 'form-data; name="myfile"; filename="cat.jpg"',
           Authorization : `Bearer ${localStorage.getItem('token')}`
          //Authorization: `Bearer ${currentUser.token}`
        }
      });*/
      
      if (!(request.body instanceof FormData)) {
        request = request.clone({
          setHeaders: {
             Authorization : `Bearer ${localStorage.getItem('token')}`
            //Authorization: `Bearer ${currentUser.token}`
          }
        });
      } else {
        request = request.clone({
          setHeaders: {
            Accept: 'application/json',
             Authorization : `Bearer ${localStorage.getItem('token')}`
            //Authorization: `Bearer ${currentUser.token}`
          }
        });
      }

    return next.handle(request);
  }
}
