import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterticket'
})
export class FilterticketPipe implements PipeTransform {

  transform(value: any, input: any): unknown {
    if (input) {
      return (value.filter(val => val.objet.indexOf(input)) >= 0);
    } else {
      return value;
    }
  }

}
