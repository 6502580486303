<li ngbDropdown class="nav-item dropdown-notification mr-25" id="Dropdown" #myDrop="ngbDropdown" autoClose="false">
    <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
      ><i class="ficon" data-feather="bell"></i
      ><span class="badge badge-pill badge-danger badge-up"  *ngIf="rows?.length > 0">{{
        rows?.length || 0
      }}</span></a
    >
    <ul
      ngbDropdownMenu autoClose="false" id="ngbDropdownMenu" (click)="myDrop.close();"
      aria-labelledby="navbarNotificationDropdown"
      class="dropdown-menu dropdown-menu-media dropdown-menu-right"
    >
      <!-- Notifications header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
          <div class="badge badge-pill badge-light-primary">
            {{ rows?.length }} {{ 'notif.new' | translate }}
          </div>
        </div>
      </li>
      <!--/ Notifications header -->
  
      <!-- Notifications content -->
      <li class="scrollable-container media-list" [perfectScrollbar]>
        <a class="d-flex" (click)="redirect(message.type, message)" *ngFor="let message of rows">
          <div *ngIf="message.type ==='MESSAGE'" class="media d-flex align-items-start">
            <div class="media-left">
              <div class="avatar bg-light-warning">
                <div class="avatar-content"><i data-feather="message-circle"></i></div>
              </div>
            </div>
            <div class="media-body" style="flex: 2;">
              <p class="media-heading">{{ 'notif.msg-rec' | translate }}</p>
              <small class="notification-text">
                {{ (message.message.contenu.length>60) ? (message.message.contenu.substring(0,59)+' ...') : (message.message.contenu) }}
              </small>
            </div>
            <div class="media-notif">
              <p class="media-heading"></p>
              <small class="notification-text">{{ message.date }}</small>
            </div>
          </div>
          <div *ngIf="message.type ==='POST'" class="media d-flex align-items-start">
            <div class="media-left">
              <div class="avatar bg-light-danger">
                <div class="avatar-content"><i data-feather="layers"></i></div>
              </div>
            </div>
            <div class="media-body">
              <p class="media-heading">{{ 'notif.inq-added' | translate }}</p>
              <small class="notification-text">{{ message.creator.fullName }} {{ 'notif.add-inq' | translate }} </small>
            </div>
            <div class="media-notif">
              <p class="media-heading"></p>
              <small class="notification-text">{{ message.date }}</small>
            </div>
          </div>
          <div *ngIf="message.type ==='POSTE1'" class="media d-flex align-items-start">
            <div class="media-left">
              <div class="avatar bg-light-danger">
                <div class="avatar-content"><i data-feather="layers"></i></div>
              </div>
            </div>
            <div class="media-body">
              <p class="media-heading">{{ 'notif.inq-added' | translate }}</p>
              <small class="notification-text">{{ message.creator.fullName }} {{ 'notif.add-inq' | translate }} </small>
            </div>
            <div class="media-notif">
              <p class="media-heading"></p>
              <small class="notification-text">{{ message.date }}</small>
            </div>
          </div>
          <div *ngIf="message.type ==='POSTCONFIRMED'" class="media d-flex align-items-start">
            <div class="media-left">
              <div class="avatar bg-light-danger">
                <div class="avatar-content"><i data-feather="layers"></i></div>
              </div>
            </div>
            <div class="media-body">
              <p class="media-heading">{{ 'notif.inq-added-confirmed' | translate }}</p>
              <small class="notification-text">{{ message.creator.fullName }} {{ 'notif.add-inq' | translate }} </small>
            </div>
            <div class="media-notif">
              <p class="media-heading"></p>
              <small class="notification-text">{{ message.date }}</small>
            </div>
          </div>
          <div *ngIf="message.type ==='CLIENT'" class="media d-flex align-items-start">
            <div class="media-left">
              <div class="avatar bg-light-success">
                <div class="avatar-content"><i data-feather="plus"></i></div>
              </div>
            </div>
            <div class="media-body">
              <p class="media-heading">{{ 'notif.client-added' | translate }}</p>
              <small class="notification-text">{{ message.client.fullName }} {{ 'notif.was-added' | translate }}</small>
            </div>
            <div class="media-notif">
              <p class="media-heading"></p>
              <small class="notification-text">{{ message.date }}</small>
            </div>
          </div>
          <div *ngIf="message.type ==='STATUS'" class="media d-flex align-items-start">
            <div class="media-left">
              <div class="avatar bg-light-danger">
                <div class="avatar-content"><i data-feather="layers"></i></div>
              </div>
            </div>
            <div class="media-body">
              <p class="media-heading">{{ 'notif.status-upd' | translate }}</p>
              <small class="notification-text">{{ message.creator.fullName }} {{ 'notif.upd-status' | translate }} </small>
            </div>
            <div class="media-notif">
              <p class="media-heading"></p>
              <small class="notification-text">{{ message.date }}</small>
            </div>
          </div>
          <div *ngIf="message.type ==='AFFECT'" class="media d-flex align-items-start">
            <div class="media-left">
              <div class="avatar bg-light-danger">
                <div class="avatar-content"><i data-feather="layers"></i></div>
              </div>
            </div>
            <div class="media-body">
              <p class="media-heading">{{ 'notif.inq-affected' | translate }}</p>
              <small class="notification-text">La demande de {{ message.creator.fullName }} {{ 'notif.wasreaffected' | translate }}</small>
            </div>
            <div class="media-notif">
              <p class="media-heading"></p>
              <small class="notification-text">{{ message.date }}</small>
            </div>
          </div>
          <div *ngIf="message.type ==='INVOICE'" class="media d-flex align-items-start">
            <div class="media-left">
              <div class="avatar bg-light-danger">
                <div class="avatar-content"><i data-feather="layers"></i></div>
              </div>
            </div>
            <div class="media-body">
              <p class="media-heading">{{ 'notif.invoice-added' | translate }}</p>
              <small class="notification-text">La facture de {{ message.creator.fullName }} {{ 'notif.was-added' | translate }}</small>
            </div>
            <div class="media-notif">
              <p class="media-heading"></p>
              <small class="notification-text">{{ message.date }}</small>
            </div>
          </div>
          <div *ngIf="message.type ==='RECLAMATION'" class="media d-flex align-items-start">
            <div class="media-left">
              <div class="avatar bg-light-danger">
                <div class="avatar-content"><i data-feather="layers"></i></div>
              </div>
            </div>
            <div class="media-body">
              <p class="media-heading">{{ 'notif.rec-added' | translate }}</p>
              <small class="notification-text">{{ message.creator.fullName }} {{ 'notif.add-rec' | translate }} </small>
            </div>
            <div class="media-notif">
              <p class="media-heading"></p>
              <small class="notification-text">{{ message.date }}</small>
            </div>
          </div>
          <div *ngIf="message.type ==='RESPONSE'" class="media d-flex align-items-start">
            <div class="media-left">
              <div class="avatar bg-light-danger">
                <div class="avatar-content"><i data-feather="layers"></i></div>
              </div>
            </div>
            <div class="media-body">
              <p class="media-heading">{{ 'notif.rec-response' | translate }}</p>
              <small class="notification-text">{{ message.creator.fullName }} {{ 'notif.add-rec' | translate }} </small>
            </div>
            <div class="media-notif">
              <p class="media-heading"></p>
              <small class="notification-text">{{ message.date }}</small>
            </div>
          </div>
        </a>
        <a class="d-flex" href="javascript:void(0)" *ngFor="let systemMessage of notifications.systemMessages">
          <div class="media d-flex align-items-start">
            <div class="media-left">
              <div
                class="avatar"
                [ngClass]="{
                  'bg-light-danger': systemMessage.icon === 'x',
                  'bg-light-success': systemMessage.icon === 'check',
                  'bg-light-warning': systemMessage.icon === 'alert-triangle'
                }"
              >
                <div class="avatar-content"><i class="avatar-icon" [data-feather]="systemMessage.icon"></i></div>
              </div>
            </div>
            <div class="media-body">
              <p class="media-heading" [innerHTML]="systemMessage.heading"></p>
              <small class="notification-text">{{ systemMessage.text }}</small>
            </div>
          </div></a
        >
      </li>
      <!--/ Notifications content -->
  
      <!-- Notifications footer -->
      <li class="dropdown-menu-footer" *ngIf="rows && rows.length > 0">
        <a class="btn btn-primary btn-block" (click)="readAllNotif()">{{ 'notif.readall' | translate }}</a>
      </li>
      <!--/ Notifications footer -->
    </ul>
  </li>
  