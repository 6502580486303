import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from 'app/auth/service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  public url = this._router.url;
  public urlLastValue;
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._authenticationService.currentUserValue;
    
    if (currentUser) {
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
        // role not authorised so redirect to not-authorized page
        //this._router.navigate(['/pages/miscellaneous/not-authorized']);
        //return false;
        return true;
      }

      // authorised so return true
      return true;
    }

    if(this.url.lastIndexOf('activation') > 0) {
      this.urlLastValue = this.url.substr(this.url.lastIndexOf('/') + 1);
      //this._router.navigate(['#/pages/miscellaneous/activation/'+this.urlLastValue]);
      return false;
    }

    // not logged in so redirect to login page with the return url
    //this._router.navigate(['/pages/authentication/login-v2'], { queryParams: { /*returnUrl: state.url */ } });
    this._authenticationService.logout();
    this._router.navigate(['/login']/*, {
      skipLocationChange: false,
    }*/);
    //this._router.navigate(['/pages/miscellaneous/activation']);
    return false;
  }
}
