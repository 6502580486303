import { ProfileService } from 'app/main/pages/profile/profile.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';

import { CoreCommonModule } from '@core/common.module';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';

import { NavbarComponent } from 'app/layout/components/navbar/navbar.component';
import { NavbarNotificationComponent } from './navbar-notification/navbar-notification.component';
import { SocketService } from 'app/main/apps/chat/services/socket.service';
import { UserEditService } from 'app/main/apps/user/user-edit/user-edit.service';
import { StatisticsadService } from 'app/main/apps/statistics-admin/statisticsad.service';
import { TranslateModule } from '@ngx-translate/core';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

@NgModule({
  declarations: [
    NavbarComponent,
    NavbarNotificationComponent
  ],
  imports: [RouterModule, NgbModule, CoreCommonModule, TranslateModule.forChild(), PerfectScrollbarModule, CoreTouchspinModule],
  providers: [SocketService, {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    ProfileService, UserEditService, StatisticsadService
  ],
  exports: [NavbarComponent]
})
export class NavbarModule {}
