import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  // Dashboard
  {
    id: 'apps',
    type: 'section',
    title: '',
    translate: '',
    icon: 'package',
    children: [
      
      {
        id: 'statisticsad',
        title: 'Dashboard',
        translate: 'MENU.APPS.STATISTICSAD.COLLAPSIBLE',
        type: 'item',
        role: ['ROLE_ADMIN'],
        icon: 'sliders',
        url: 'apps/statistics/statistics-admin'
      },
      {
        id: 'statisticscl',
        title: 'Dashboard',
        translate: 'MENU.APPS.STATISTICSCL.COLLAPSIBLE',
        type: 'item',
        role: ['ROLE_CLIENT', 'ROLE_E1'],
        icon: 'activity',
        url: 'apps/statisticscl/statistics-client'
      },{
        id: 'requests',
        title: 'All Inquiries',
        translate: 'MENU.APPS.REQUESTS.COLLAPSIBLE',
        type: 'item',
        role: ['ROLE_ADMIN', 'ROLE_E1', 'ROLE_E3'],
        icon: 'credit-card',
        url: 'apps/request/request-list'
      },/*{
        id: 'otherrequests',
        title: 'All Other Inquiries',
        translate: 'MENU.APPS.OTHERREQUESTS.COLLAPSIBLE',
        type: 'item',
        role: ['ROLE_ADMIN', 'ROLE_E1', 'ROLE_E2', 'ROLE_E3'],
        icon: 'credit-card',
        url: 'apps/otherrequest/otherrequest-list'
      },*/
      {
        id: 'myrequests',
        title: 'My Inquiries',
        translate: 'MENU.APPS.MYREQUESTS.COLLAPSIBLE',
        type: 'item',
        role: ['ROLE_CLIENT'],
        icon: 'credit-card',
        url: 'apps/request/request-list'
      },
      {
        id: 'agentrequests',
        title: 'My Affected Inquiries',
        translate: 'MENU.APPS.AGENTREQUESTS.COLLAPSIBLE',
        type: 'item',
        role: ['ROLE_E2', 'ROLE_ADMIN'],
        icon: 'check-square',
        url: 'apps/request/request-affected'
      },
      {
        id: 'archive',
        title: 'Archive',
        translate: 'MENU.APPS.ARCHIVE.COLLAPSIBLE',
        type: 'item',
        role: ['ROLE_CLIENT', 'ROLE_E1'],
        icon: 'save',
        url: 'apps/request/request-archive'
      },
      {
        id: 'users',
        title: 'Users',
        translate: 'MENU.APPS.USER.COLLAPSIBLE',
        type: 'item',
        role: ['ROLE_ADMIN'],
        icon: 'user',
        url: 'apps/user/user-list'
      },
      {
        id: 'invoices',
        title: 'All Invoices',
        translate: 'MENU.APPS.INVOICES.COLLAPSIBLE',
        type: 'item',
        role: ['ROLE_ADMIN'],
        icon: 'inbox',
        url: 'apps/invoices/invoices-list'
      },
      {
        id: 'myinvoices',
        title: 'My Invoices',
        translate: 'MENU.APPS.MYINVOICES.COLLAPSIBLE',
        type: 'item',
        role: ['ROLE_CLIENT', 'ROLE_E1'],
        icon: 'inbox',
        url: 'apps/invoices/invoices-list'
      },
      {
        id: 'reclamation',
        title: 'Claims',
        translate: 'MENU.APPS.RECLAMATION.COLLAPSIBLE',
        type: 'item',
        role: ['ROLE_CLIENT', 'ROLE_ADMIN', 'ROLE_E1', 'ROLE_E2', 'ROLE_E3'],
        icon: 'minus-square',
        url: 'apps/reclamation/reclamation-list'
      },/*
      {
        id: 'agentinvoices',
        title: 'My Affected Invoices',
        translate: 'MENU.APPS.AGENTINVOICES.COLLAPSIBLE',
        type: 'item',
        role: ['ROLE_E2', 'ROLE_ADMIN'],
        icon: 'check-square',
        url: 'apps/invoices/invoices-affected'
      },*/
      {
        id: 'faq',
        title: 'FAQ',
        translate: 'MENU.APPS.FAQ.COLLAPSIBLE',
        type: 'item',
        role: ['ROLE_CLIENT', 'ROLE_ADMIN', 'ROLE_E1', 'ROLE_E2', 'ROLE_E3'],
        icon: 'help-circle',
        url: 'apps/faq/faq'
      },
      {
        id: 'contact',
        title: 'Contact Us',
        translate: 'MENU.APPS.CONTACT.COLLAPSIBLE',
        type: 'item',
        role: ['ROLE_CLIENT', 'ROLE_ADMIN', 'ROLE_E1', 'ROLE_E2', 'ROLE_E3'],
        icon: 'command',
        url: 'apps/contactus/contactus'
      },
      {
        id: 'parrain',
        title: 'Sponsorship',
        translate: 'MENU.APPS.PARRAIN.COLLAPSIBLE',
        type: 'item',
        role: ['ROLE_CLIENT', 'ROLE_ADMIN', 'ROLE_E2'],
        icon: 'git-pull-request',
        url: 'apps/parrain/parrain'
      }
    ]
  }
];
