import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs';
import { Message } from '../models/message';
import { Event } from '../models/event';
import * as socketIo from 'socket.io-client';


//const SERVER_URL = "http://localhost:8080";
const SERVER_URL = "https://socket.vetasource.com";

@Injectable()
export class SocketService {
    private socket;

    public initSocket(): void {
        this.socket = socketIo(SERVER_URL);
    }

    /*
    public send(message: Message): void {
        this.socket.emit('message', message);
    }*/
    
    public send(message: Message): void {
        // console.log("file "+message.file.name);
        this.socket.emit('message', message);
        console.log("emitted affected");
        /*
        this.socket.emit("upload", message.file, (status) => {
            console.log("status"+status);
          });*/
    }

    public onMessage(): Observable<Message> {
        return new Observable<Message>(observer => {
            this.socket.on('message', (data: Message) => {
                console.log("data"+JSON.stringify(data));
                observer.next(data)});
        });
    }

    public joinRoom(matchId) {
        try {
          //this.socket = io.connect('http://localhost:3000');
          this.socket.emit('join room', matchId.toString());
        } catch (e) {
         console.log('Could not connect socket.io');
        }
      }


    public onEvent(event: Event): Observable<any> {
        return new Observable<Event>(observer => {
            this.socket.on(event, () => observer.next());
        });
    }
}
