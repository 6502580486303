import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { stringify } from '@angular/compiler/src/util';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;
  public user: User;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    //console.log("authent.service"+this.isClient);
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    console.log("herrreee");
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   *  Confirms if user is agent
   */
   get isAgent() {
    return this.currentUser && this.currentUserSubject.value.role === Role.E2;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
   login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.API_URL}/api/login_check`, { "email": email, "password": password })
      .pipe(
        map(response => {
          // login successful if there's a jwt token in the response
          if (response && response.token && !response.error) {
            //this.user
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(response.data));
            localStorage.setItem('token', JSON.stringify(response.token).slice(1, -1));
            localStorage.setItem('iduser', JSON.stringify(response.data.id));
            localStorage.setItem('username', response.data.username);
            localStorage.setItem('avatar', response.data.avatar);
            localStorage.setItem('role', response.data.role);
            var only_role = response.data.role.split("ROLE_");
            // Display welcome toast!
            
            setTimeout(() => {
              this._toastrService.success(
                'You have successfully logged in as an ' +
                  only_role +
                  ' user to Vetasource. Now you can start to explore. Enjoy! 🎉',
                '👋 Welcome, ' + response.data.firstName + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(response.data);
          }

          return response;
        })
      );
  }




  /*
   login(email: string, password: string) {
    const headers2 = new HttpHeaders();
        headers2.append('Access-Control-Allow-Headers', 'Content-Type');
        headers2.append('Access-Control-Allow-Methods', 'GET');
        headers2.append('Access-Control-Allow-Origin', '*');
    /*const params = new HttpParams()
        .set('lat', 'one!')
        .set('lng', "two");
        console.log(params);
    const user = {
      "email": "firas.mimoun4@esprit.tn",
      "password": "123456789"
  }
  const params2 = new HttpParams().set("email",email).set("password", password);
  const params3 = new HttpParams().append('email', 'email').append('password', password);
  const params4 = new HttpParams();
  params4.append('email', 'email');
  params4.append('password', password);
    return this._http
      .post<any>(`${environment.backUrl}/api/login_check`, { headers: headers2,
        params: user, 
        responseType: 'json' });
  }*/
  /*
  login(email: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/users/authenticate`, { email, password })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                'You have successfully logged in as an ' +
                  user.role +
                  ' user to Vuexy. Now you can start to explore. Enjoy! 🎉',
                '👋 Welcome, ' + user.firstName + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );
  }*/

  register(email: string, firstname: string, lastname: string, country: string, tel: string, password: string, parrain: string) {
    return this._http
      .post<any>(`${environment.API_URL}/register`, { "firstname": firstname,"lastname": lastname,"country": country,"tel": tel, "password": password , "email": email, "roles": "ROLE_CLIENT", "parrain": parrain})
      .pipe(
        map(response => {
          

          return response.status;
        })
      );
  }

  addUser(email: string, firstname: string, lastname: string, country: string, tel: string, password: string, role: string) {
    return this._http
      .post<any>(`${environment.API_URL}/adduser`, { "firstname": firstname,"lastname": lastname,"country": country,"tel": tel, "password": password , "email": email, "roles": role})
      .pipe(
        map(response => {
          

          return response;
        })
      );
  }

  sendResetLink(email: string) {
    return this._http
      .post<any>(`${environment.API_URL}/forgetPsw`, { "email": email})
      .pipe(
        map(response => {
          return response.status;
        })
      );
  }


  resetPassword(newpassword: string, token: string) {
    return this._http
      .post<any>(`${environment.API_URL}/resetPsw`, { "token": token, "newpassword": newpassword})
      .pipe(
        map(response => {
          

          return response.status;
        })
      );
  }

  verifyAccount(token: string) {
    return this._http
      .post<any>(`${environment.API_URL}/activation`, { "token": token})
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  getPost(id: string) {
    return this._http
      .get<any>(`${environment.API_URL}/track/tracking/`+id, {})
      .pipe(
        map(response => {
          console.log("json "+JSON.stringify(response))
          return response;
        })
      );
  }

  getFilesByPost(id: number){
    let auth_token = localStorage.getItem('token');
    const headers = new HttpHeaders({
     'Content-Type': 'application/json',
     'Authorization': `Bearer ${auth_token}`
    });
   
    const requestOptions = { headers: headers };
    return this._http.get(`${environment.API_URL}/api/files/${id}`, requestOptions).pipe(
      map(response => {
        return response;
      })
    );
  }

  getFilesBoxesByPost(id: number){
    let auth_token = localStorage.getItem('token');
    const headers = new HttpHeaders({
     'Content-Type': 'application/json',
     'Authorization': `Bearer ${auth_token}`
    });
   
    const requestOptions = { headers: headers };
    return this._http.get(`${environment.API_URL}/api/filesboxes/${id}`, requestOptions).pipe(
      map(response => {
        return response;
      })
    );
  }

  getFilesRealsByPost(id: number){
    let auth_token = localStorage.getItem('token');
    const headers = new HttpHeaders({
     'Content-Type': 'application/json',
     'Authorization': `Bearer ${auth_token}`
    });
   
    const requestOptions = { headers: headers };
    return this._http.get(`${environment.API_URL}/api/filesreals/${id}`, requestOptions).pipe(
      map(response => {
        return response;
      })
    );
  }


  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }
  
}
