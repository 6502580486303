<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section class="card" style="margin-bottom: 0rem; margin-top: 2rem; padding: 1rem; font-size: 20px; font-weight: 500;">Suivi Demande</section>
    <section class="invoice-preview-wrapper">
      <div class="row invoice-preview" style="margin-right: -1rem; margin-left: 0rem; height: calc(100vh - 8rem); margin-top: 1rem;">
        <!-- Invoice -->
        <div class="col-xl-9 col-md-8 col-12 printRef card">
            <div>
              <header  class="details-header" style="background-color: transparent;">
                <div class="d-flex align-items-center">
                  <h3 class="mb-0">Details</h3>
                </div>
              </header>
            </div>
            <div class="row" style="margin-top: 20px;">
              <div class="col-lg-3 col-12 my-lg-0 my-2">
                <div class="form-group">
                  <label for="customer">{{ 'inquires-add.customername' | translate }}</label>
                  <input [disabled]="disabled"
                    type="text"
                    id="customer"
                    class="form-control"
                    placeholder="Customer"
                    name="customer"
                    [(ngModel)]="data.customer"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-12 my-lg-0 my-2">
                <div class="form-group">
                  <label for="product">{{ 'inquires-add.productname' | translate }}</label>
                  <input [disabled]="disabled"
                    type="text"
                    id="product"
                    class="form-control"
                    placeholder="Product"
                    name="product"
                    [(ngModel)]="data.product"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-12 my-lg-0 my-2">
                <div class="form-group">
                  <label for="url">{{ 'inquires-add.url' | translate }}</label>
                  <input disabled
                    type="url"
                    id="url"
                    class="form-control"
                    placeholder="url"
                    name="url"
                    [(ngModel)]="data.url"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-12 my-lg-0 my-2">
                <div class="form-group">
                  <label for="quantity">{{ 'inquires-edit.quantity' | translate }}</label>
                  <input [disabled]="disabled"
                    type="quantity"
                    id="quantity"
                    class="form-control"
                    placeholder="Quantity"
                    name="quantity"
                    [(ngModel)]="data.quantity"
                  />
                </div>
              </div>
              <!--<div class="col-lg-3 col-12 my-lg-0 my-2">
                <div class="form-group">
                  <label for="comment">{{ 'inquires-edit.comment' | translate }}</label>
                  <input [disabled]="disabled"
                    type="text"
                    id="comment"
                    class="form-control"
                    placeholder="Comment"
                    name="comment"
                    [(ngModel)]="data.comment"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-12 my-lg-0 my-2">
                <div class="form-group">
                  <label for="url">Url</label>
                  <input [disabled]="disabled"
                    type="url"
                    id="url"
                    class="form-control"
                    placeholder="Url"
                    name="url"
                    [(ngModel)]="data.url"
                  />
                </div>
              </div>-->
              <div class="col-lg-3 col-12 my-lg-0 my-2">
                <div class="form-group">
                  <label for="company">{{ 'inquires-edit.company' | translate }}</label>
                  <input disabled
                    type="company"
                    id="company"
                    class="form-control"
                    placeholder="Company"
                    name="company"
                    [(ngModel)]="data.company.name"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-12 my-lg-0 my-2">
                <div class="form-group">
                  <label for="destination">{{ 'inquires-edit.destination' | translate }}</label>
                  <input disabled
                    type="destination"
                    id="destination"
                    class="form-control"
                    placeholder="Destination"
                    name="destination"
                    [(ngModel)]="data.destination"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-12 my-lg-0 my-2">
                <div class="form-group">
                  <label for="salesperson">{{ 'inquires-edit.colorsize' | translate }}:</label>
                  <input type="text" disabled [(ngModel)]="data.color" name="color" class="form-control" id="salesperson" placeholder="color" />
                </div>
              </div>
              <!--<div class="col-lg-3 col-12 my-lg-0 my-2" >
                <div class="form-group">
                  <label for="quantity">{{ 'inquires.payment' | translate }}</label>
                  <select  class="form-control tax-select" disabled
                              name="payment"
                              [(ngModel)]="data.payment">
                              <option value="0">False</option>
                              <option value="1">True</option></select>
                </div>
              </div>
              <div class="col-lg-3 col-12 my-lg-0 my-2">
                <div class="form-group">
                  <label for="purchase">{{ 'inquires-edit.purchase' | translate }}</label>
                  <select  class="form-control tax-select" disabled
                              name="purchase"
                              [(ngModel)]="data.purchase">
                              <option value="0">False</option>
                              <option value="1">True</option></select>
                </div>
              </div>
              <div class="col-lg-3 col-12 my-lg-0 my-2">
                <div class="form-group">
                  <label for="pictures">{{ 'inquires-edit.pictures' | translate }}</label>
                  <select  class="form-control tax-select" disabled
                              name="pictures"
                              [(ngModel)]="data.pictures">
                              <option value="0">False</option>
                              <option value="1">True</option></select>
                </div>
              </div>
              <div class="col-lg-3 col-12 my-lg-0 my-2">
                <div class="form-group">
                  <label for="color">{{ 'inquires-edit.colorsize' | translate }}</label>
                  <input disabled
                    type="text"
                    id="color"
                    class="form-control"
                    placeholder="Color/Size"
                    name="color"
                    [(ngModel)]="data.color"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-12 my-lg-0 my-2">
                <div class="form-group">
                  <label for="cost_price">{{ 'inquires.cost_price' | translate }}</label>
                  <input disabled
                    type="text"
                    id="cost_price"
                    class="form-control"
                    placeholder="Cost_price"
                    name="cost_price"
                    [(ngModel)]="data.cost_price"
                  />
                </div>
              </div>-->
              <div class="col-lg-3 col-12 my-lg-0 my-2">
                <div class="form-group">
                  <label for="selling_price">{{ 'inquires.selling_price' | translate }}</label>
                  <input disabled
                    type="text"
                    id="selling_price"
                    class="form-control"
                    placeholder="Selling_price"
                    name="selling_price"
                    [(ngModel)]="data.selling_price"
                  />
                </div>
              </div>
              <div class="col-lg-3 col-12 my-lg-0 my-2" >
                <label for="weight">{{ 'inquires.weight' | translate }}</label>
                <input disabled
                  type="text"
                  class="form-control"
                  name="weight"
                  [(ngModel)]="data.weight"
                  id="weight"
                  placeholder="0"
                />
              </div>
            </div>
            <div style="margin-top: 20px;" *ngIf="requestFiles.length > 0">
              <h4 class="card-title">Photos</h4>
              <div class="card-body" style="padding-top: 0px;">
                <swiper class="swiper-multiple swiper-container" [config]="swiperMultiple">
                  <!--<img *ngFor="let row of rows;" class="img-fluid" src={{rows.name}} alt="banner" />-->
                  
                  <img *ngFor="let row of requestFiles;" style="max-width: 120px;" class="img-fluid" src="{{path}}{{ row.name }}" alt="banner" (click)="openModal(row.name)"/>
                  <!--<img class="img-fluid" src="assets/images/banner/banner-12.jpg" alt="banner" />
                  <img class="img-fluid" src="assets/images/banner/banner-9.jpg" alt="banner" />
                  <img class="img-fluid" src="assets/images/banner/banner-8.jpg" alt="banner" />
                  <img class="img-fluid" src="assets/images/banner/banner-7.jpg" alt="banner" />
                  -->
                </swiper>
              </div>
            </div>
            <div style="margin-top: 20px;" *ngIf="requestFilesReals.length > 0">
              <h4 class="card-title">Photos Réelles</h4>
              <div class="card-body" style="padding-top: 0px;">
                <swiper class="swiper-multiple swiper-container" [config]="swiperMultiple">
                  <!--<img *ngFor="let row of rows;" class="img-fluid" src={{rows.name}} alt="banner" />-->
                  
                  <img *ngFor="let row of requestFilesReals;" style="max-width: 120px;" class="img-fluid" src="{{pathreals}}{{ row.name }}" alt="banner" (click)="openModal(row.name)"/>
                  <!--<img class="img-fluid" src="assets/images/banner/banner-12.jpg" alt="banner" />
                  <img class="img-fluid" src="assets/images/banner/banner-9.jpg" alt="banner" />
                  <img class="img-fluid" src="assets/images/banner/banner-8.jpg" alt="banner" />
                  <img class="img-fluid" src="assets/images/banner/banner-7.jpg" alt="banner" />
                  -->
                </swiper>
              </div>
            </div>
            <div style="margin-top: 20px;" *ngIf="requestFilesBoxes.length > 0">
              <h4 class="card-title">Boxes</h4>
              <div class="card-body" style="padding-top: 0px;">
                <swiper class="swiper-multiple swiper-container" [config]="swiperMultiple">
                  <!--<img *ngFor="let row of rows;" class="img-fluid" src={{rows.name}} alt="banner" />-->
                  
                  <img *ngFor="let row of requestFilesBoxes;" style="max-width: 120px;" class="img-fluid" src="{{pathboxes}}{{ row.name }}" alt="banner" (click)="openModal(row.name)"/>
                  <!--<img class="img-fluid" src="assets/images/banner/banner-12.jpg" alt="banner" />
                  <img class="img-fluid" src="assets/images/banner/banner-9.jpg" alt="banner" />
                  <img class="img-fluid" src="assets/images/banner/banner-8.jpg" alt="banner" />
                  <img class="img-fluid" src="assets/images/banner/banner-7.jpg" alt="banner" />
                  -->
                </swiper>
              </div>
            </div>
        </div>
        <!--/ Invoice -->
        

        <!-- Invoice Actions -->
        <div class="col-xl-3 col-md-4 col-12 invoice-actions mt-md-0 mt-2" style="margin-bottom: 2rem;">
          <div class="card" style="height: 100%;">
            <div class="card-body">
              <div>
                <label style="font-size: 1.2rem;">{{ 'profil.e1' | translate }}</label>
                <input disabled
                  type="text"
                  class="form-control"
                  name="support"
                  [(ngModel)]="data.e1.fullName"
                  id="support"
                  placeholder="support"
                />
              </div>
              <div>
                <hr>
                <label style="font-size: 1.2rem;">Statut</label>
                <input disabled
                  type="text"
                  class="form-control"
                  name="status"
                  [(ngModel)]="data.status"
                  id="status"
                  placeholder="statut"
                />
              </div>
              <!--<div class="input-group input-group-lg mb-1" style="margin-top: 10px;">
                <div type="text" placeholder="Username" class="form-control" style="height: 115px;">
                  <h5 class="mb-0">Tracking Number</h5>
                  <span style="color: #005778;">{{ track_nbr }}</span><br>
                  <h5 class="mb-0" style="margin-top: 5px;">Tracking Url</h5>
                  <span style="color: #005778;"><a href="https://{{track_url}}" target="_blank">{{ track_url }}</a></span>
                </div>
              </div><hr>-->
            </div>
          </div>
        </div>

        
        
        <!--/ Invoice Actions -->
      </div>
    </section>
  </div>

 
</div>

<ng-template #wizard let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1"></h4>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <img id="modal_img" src="{{img_clicked}}" style="width: 100%;height: 100%;">
  </div>
</ng-template>
