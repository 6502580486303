import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  // Public
  public apiData = [];
  public onApiDataChange: BehaviorSubject<any>;

  /**
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
    this.onApiDataChange = new BehaviorSubject('');
    //this.getNotificationsData();
  }

  /**
   * Get Notifications Data
   */
  getNotificationsData(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._httpClient.get('api/notifications-data').subscribe((response: any) => {
        this.apiData = response;
        this.onApiDataChange.next(this.apiData);
        resolve(this.apiData);
      }, reject);
    });
  }

  getNotifications(id: number) {
    let auth_token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`
    });
   
    const requestOptions = { headers: headers };
    return this._httpClient.get(`${environment.API_URL}/api/allnotifications/${id}`, requestOptions).pipe(
      map(response => {
        return response;
      })
    );
  }

  setNotifVisible(id) {
    return this._httpClient
      .put<any>(`${environment.API_URL}/api/readnotif/${id}`, {})
      .pipe(
        map(response => {
          return response.status;
        })
      );
  }

  readAllNotif(id) {
    return this._httpClient
      .put<any>(`${environment.API_URL}/api/readallnotif/${id}`, {})
      .pipe(
        map(response => {
          return response.status;
        })
      );
  }
}
